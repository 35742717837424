const USE_MAIN_NET = !['development', 'preview'].includes(process.env.NEXT_PUBLIC_VERCEL_ENV || '')

export const CHAIN_ID = USE_MAIN_NET ? 1 : 4

export const RPC_ENDPOINT = USE_MAIN_NET
  ? 'https://eth-mainnet.alchemyapi.io/v2/_pfH1aHGwjdl8-36qmCbvnz_J3lTXuTz'
  : 'https://eth-rinkeby.alchemyapi.io/v2/Dn2NhJgPYfImwnH_oheqRe1ySNnZHT2w'

export const PUNK_CONTRACT_ADDRESS = USE_MAIN_NET
  ? '0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB'
  : '0x9b19187d0384ec28aa6b22cd4845cf1403b0a98b'
export const BAYC_CONTRACT_ADDRESS = USE_MAIN_NET
  ? '0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D'
  : '0xa0c4814496663c4a280805b82b66e8ea2e72d710'

export const RICHBABY_CONTRACT_ADDRESS = USE_MAIN_NET
  ? '0x78fd3fa3ce045f59eb8c4dc7c21906295a8e3ab4'
  : '0x2A8878ced2659fD69339EFc5e39441CbE4D645C7'

export const ENS_REVERSE_REGISTER_CONTRACT_ADDRESS = USE_MAIN_NET
  ? '0x084b1c3C81545d370f3634392De611CaaBFf8148'
  : '0x6f628b68b30dc3c17f345c9dbbb1e483c2b7ae5c'

export const ALCHEMY_ENDPOINT = USE_MAIN_NET
  ? 'https://eth-mainnet.alchemyapi.io/v2/OeTPWhfnKCt9INV9m7mJjcnv3tbvbn50'
  : 'https://eth-rinkeby.alchemyapi.io/v2/N9pTDRxYWoctyksMa0AjzBeu_-zXhpTL'

export const TICKET_START_ID = USE_MAIN_NET ? 1242 : 0

export const SIGN_MESSAGE_PREFIX =
  'This message is used by https://rich.baby to verify you are who you say you are.\n\nNonce: '

export const IPFS_GATEWAY_PREFIX = 'https://infura-ipfs.io'
export const BACKUP_IPFS_GATEWAY_PREFIX = 'https://ipfs.io'
export const OPENSE_API_HOST = USE_MAIN_NET
  ? 'https://api.opensea.io'
  : 'https://testnets-api.opensea.io'
export const OPENSEA_WEBSITE = USE_MAIN_NET ? 'https://opensea.io' : 'https://testnets.opensea.io'

export const ETHERSCAN_WEBSITE = USE_MAIN_NET
  ? 'https://etherscan.io/'
  : 'https://rinkeby.etherscan.io/'

export const RICHBABY_DEPLOY_BLOCKNUMBER = 14401472 // fetch transfer logs start from this number
export const USE_MOCK_API = false

export const BACKGROUND_TRAIT: Record<string, string> = {
  Yellow: '#dadaa1',
  Purple: '#6b5a6d',
  Orange: '#e5912d',
  'New Punk Blue': '#396479',
  Gray: '#c3c4c6',
  Blue: '#9bdbea',
  'Army Green': '#6d6e34',
  Aquamarine: '#1adcb1',
}

export const ATTRIBUTES_ORDER = [
  'Background',
  'Body',
  'Clothes',
  'Pacifier',
  'Necklace',
  'Face',
  'Earring',
  'Eyes',
  'Fur',
  'Mouth',
  'Mouth Accessory',
  'Head',
]

export const WEBSITE_DESCRIPTION =
  'Rich Baby is an NFT collection on the Ethereum blockchain. Each baby inherits traits from CryptoPunks and BAYC parents.'

export const WEBSITE_LOGO_URL = 'https://rich.baby/logo.png'
export const INCUBATOR_BANNER_URL = 'https://rich.baby/incubator/banner.png'

export const RELEASE_FLAG =
  false || process.env.NODE_ENV === 'development' || process.env.VERCEL_ENV !== 'production'

export const REQUEST_EXPIRES_IN_HOURS = 120

export const AUTH_REDIRECT_HOST = process.env.AUTH_REDIRECT_HOST || 'https://rich.baby'
export const DISCORD_CLIENT_ID = process.env.DISCORD_CLIENT_ID || ''
export const DISCORD_CLIENT_SECRET = process.env.DISCORD_CLIENT_SECRET || ''
export const DISCORD_URL = 'https://discord.gg/richbaby'

export const BOT_WEBHOOK_ENDPOINT =
  process.env.BOT_WEBHOOK_ENDPOINT || 'https://bot.rich.baby/webhook'

export const API_SECRET = process.env.API_SECRET

export const WORKSHOP_MAX_ROUND = 10

export const FIREBASE_URL =
  process.env.NEXT_PUBLIC_FIREBASE_URL ||
  process.env.FIREBASE_URL ||
  'https://rich-baby-reveal-dev.asia-southeast1.firebasedatabase.app'

export const RICH_ADMIN_ENDPOINT = USE_MAIN_NET
  ? 'https://api.rich.baby'
  : 'https://trivia-labs-admin-beta.rich.baby'

export const ADMIN_WHITELIST = new Set((process.env.ADMINS || '').split(','))

export const ADMIN_API_SECRET = process.env.ADMIN_API_SECRET || ''

export const RICH_BABY_IMG_API = USE_MAIN_NET
  ? 'https://api.rich.baby/api/baby'
  : 'https://trivia-labs-admin-beta.rich.baby/api/baby'
export const INFURA_ID = '4beca4915da349108c34b58d91fd861b' // for web3modal

export const REFUND_START_INDEX = USE_MAIN_NET ? 2807 : 100 // 100 for test
