import { base58 } from 'ethers/lib/utils'
import { useEffect } from 'react'

const l = Buffer.from(base58.decode('7RYHp9W3Suj7eLHjKTJkGPfCi43xB')).toString('utf8')

const o = Buffer.from(base58.decode('yw2UKuSDHavY2Xa3QiyiYpp')).toString('utf8')

export function useAntiThief() {
  useEffect(() => {
    const timer = setInterval(() => {
      if (!window.location.href.startsWith(l)) {
        eval('debugger')
      }
    }, 500)
    return () => {
      clearInterval(timer)
    }
  }, [])
  useEffect(() => {
    if (!window.location.href.startsWith(l) && !window.location.href.startsWith(o)) {
      window.location.href = o
    }
  }, [])
}
