import '~/styles/globals.css'
import type { AppProps } from 'next/app'
import queryClient from '~/api/queryClient'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { DefaultSeo } from 'next-seo'
import NextNProgress from 'nextjs-progressbar'
import PlausibleProvider from 'next-plausible'
import { WEBSITE_DESCRIPTION, WEBSITE_LOGO_URL } from '~/utils/constants'
import { useAntiThief } from '~/hooks/use-anti-thief'

function MyApp({ Component, pageProps }: AppProps) {
  useAntiThief()
  return (
    <QueryClientProvider client={queryClient}>
      <DefaultSeo
        description={WEBSITE_DESCRIPTION}
        defaultTitle="Rich Baby"
        openGraph={{
          type: 'website',
          description: WEBSITE_DESCRIPTION,
          images: [
            {
              url: WEBSITE_LOGO_URL,
              width: 900,
              height: 900,
              alt: 'Rich Baby',
            },
          ],
        }}
        twitter={{
          site: '@yesrichbaby',
          cardType: 'summary_large_image',
        }}
      />
      <PlausibleProvider domain="rich.baby">
        <Component {...pageProps} />
      </PlausibleProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      <NextNProgress color="var(--baby-yellow)" options={{ showSpinner: false }} />
    </QueryClientProvider>
  )
}

export default MyApp
